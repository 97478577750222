import React from 'react';

// Components
import Layout from 'components/Layout';
import PostLink from 'components/Post-Link';
import { Link } from 'gatsby';

// Utilities
import { propTypes } from 'shapes/templates/tags';
import { graphql } from 'gatsby';

const pluralize = (num, singular, suffix = 's') =>
    num === 1 ? singular : singular + suffix;

const Tags = ({
    pathContext,
    data: {
        allMarkdownRemark: { edges, totalCount },
    },
}) => {
    const { tag } = pathContext;

    if (tag) {
        const tagHeader = `${totalCount} ${pluralize(
            totalCount,
            'post'
        )} tagged with "${tag}"`;

        return (
            <Layout>
                <div>
                    <h1 className="mb-4">{tagHeader}</h1>
                    {edges.map(({ node }) => (
                        <PostLink key={node.frontmatter.path} post={node} />
                    ))}
                    <Link
                        to="/tags"
                        className="btn btn-outline-primary"
                        href="#"
                        role="button"
                    >
                        All tags
                    </Link>
                </div>
            </Layout>
        );
    }
};

Tags.propTypes = propTypes;

export default Tags;

export const pageQuery = graphql`
    query($tag: String) {
        allMarkdownRemark(
            limit: 1000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: {
                frontmatter: { tags: { in: [$tag] }, published: { ne: false } }
            }
        ) {
            totalCount
            edges {
                node {
                    excerpt
                    html
                    wordCount {
                        words
                    }
                    timeToRead
                    frontmatter {
                        title
                        path
                        date
                    }
                }
            }
        }
    }
`;
